import { FC } from 'react';

import { type InputProps } from 'antd';

import { IconSearch } from '@assets';
import { colors } from '@theme';

import * as S from './styled';

export type SearchControlProps = {
  width?: number;
  fitWidth?: boolean;
} & InputProps;

const SearchControl: FC<SearchControlProps> = ({
  width,
  fitWidth,
  ...rest
}) => (
  <S.Control
    size="large"
    placeholder="Search"
    prefix={<IconSearch color={colors.gray} />}
    variant="filled"
    width={width}
    fitWidth={fitWidth}
    {...rest}
  />
);

export default SearchControl;
