import { FC, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  Flex,
  InputProps,
  message,
  Modal,
  Popover,
  Table,
  type TableColumnType,
} from 'antd';

import {
  DocumentsStatusTag,
  DownloadDocumentsModal,
  FilterConstructor,
  SendRequestModal,
} from '@entities';
import {
  useGetDocumentsList,
  useRunRecognitionDocument,
  useUpdateDocumentById,
  useUpdateDocumentsById,
} from '@hooks-api';
import { useAccount, useClickOutside, useInfiniteScrollByPage } from '@hooks';
import { getSearchParams, paginationNormalizer } from '@utils';
import { IconClose, IconDownload, IconMoreVertical } from '@assets';
import { statusesList, typesList } from '@constants';

import { Schemas } from '@api-client/generated/types';
import { Key } from 'antd/es/table/interface';

import * as S from './styled';

type DocumentFile = Schemas.Document;

type FilterOptions = Record<string, unknown>;

type DocumentsContentProps = {
  title?: ReactNode;
  withSearchParams?: boolean;
  paginationCurrentPage?: number;
  externalCompanyId?: string;
  onPagination?: (metaData: Schemas.PaginationMetadata) => void;
  onFilter?: (options: FilterOptions) => void;
};

const DocumentsContent: FC<DocumentsContentProps> = ({
  title,
  withSearchParams = true,
  paginationCurrentPage,
  externalCompanyId,
  onPagination,
  onFilter,
}) => {
  const { companyId: accountCompanyId } = useAccount();

  const companyId = externalCompanyId || accountCompanyId;

  const { search } = useLocation();
  const navigate = useNavigate();

  const params = getSearchParams<{ [key: string]: string }>(search);

  const [files, setFiles] = useState<DocumentFile[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [actionVisibleId, setActionVisibleId] = useState('');
  const [isOpenRequestModal, setIsOpenRequestModal] = useState(false);
  const [isOptionsPopoverOpen, setIsOptionsPopoverOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});

  const ref = useClickOutside(() => setIsOptionsPopoverOpen(false));
  const [modal, contextHolder] = Modal.useModal();

  const { currentPage: currentPageByScroll, resetScroll } =
    useInfiniteScrollByPage({ isLastPage });

  const { loading, refetch } = useGetDocumentsList({
    params: {
      companyId: companyId!,
      page: paginationCurrentPage || currentPageByScroll,
      ...filterOptions,
    },
    config: {
      onSuccess: ({
        records,
        metadata,
      }: {
        records: DocumentFile[];
        metadata: Schemas.PaginationMetadata;
      }) => {
        if (paginationCurrentPage) {
          onPagination?.(metadata);
          onFilter?.(filterOptions);

          setFiles(records);
        } else {
          const normalizeTransactions = paginationNormalizer<DocumentFile>({
            prevData: files,
            scrollPage: currentPageByScroll,
            setCurrentPage,
            currentPage,
            records,
            metadata,
          });

          if (metadata.currentPage === metadata.totalPages) {
            setIsLastPage(true);
          }

          if (normalizeTransactions) {
            setFiles(normalizeTransactions);
          }
        }
      },
    },
  });

  const [updateFile] = useUpdateDocumentById();
  const [updateFiles] = useUpdateDocumentsById();
  const [recognizeFile] = useRunRecognitionDocument();

  const handleUpdateFile = (
    id: string,
    key: string,
    value?: string,
    withMessage: boolean = true
  ) => {
    updateFile(
      {
        parameter: {
          id: id!,
          companyId: companyId!,
        },
        requestBody: {
          [key]: value,
        },
      },
      {
        onSuccess: () => {
          withMessage &&
            message.open({
              type: 'success',
              content: 'File information has been successfully updated',
            });
        },
      }
    );
  };

  const handleReport = (code: string) => {
    updateFile(
      {
        parameter: {
          companyId: companyId!,
          id: actionVisibleId,
        },
        requestBody: {
          errorCode: code,
          adminStatus: 'requested_changes',
        },
      },
      {
        onSuccess: () => {
          setIsOpenRequestModal(false);
          setActionVisibleId('');
          refetch();
          message.success('Report was sent');
        },
      }
    );
  };

  const handleConfirmBooked = () => {
    modal.confirm({
      title: 'Are you sure you want to mark as booked this file?',
      okButtonProps: {
        size: 'large',
      },
      cancelButtonProps: {
        size: 'large',
      },
      icon: null,
      onOk: () => {
        handleUpdateFile(actionVisibleId, 'adminStatus', 'processed');
        setFiles((prev) =>
          prev.map((file) =>
            file.id === actionVisibleId
              ? { ...file, adminStatus: 'processed' }
              : file
          )
        );
        setActionVisibleId('');
      },
      onCancel: () => {
        setActionVisibleId('');
      },
    });
  };

  const handleConfirmBookedFiles = () => {
    modal.confirm({
      title: 'Are you sure you want to mark as booked these files?',
      okButtonProps: {
        size: 'large',
      },
      cancelButtonProps: {
        size: 'large',
      },
      icon: null,
      onOk: handleBookFiles,
      onCancel: () => {
        setActionVisibleId('');
      },
    });
  };

  const handleConfirmRecognizeFile = () => {
    modal.confirm({
      title: 'Are you sure you want to recognize this file?',
      okButtonProps: {
        size: 'large',
      },
      cancelButtonProps: {
        size: 'large',
      },
      icon: null,
      onOk: () => {
        handleFileRecognize(actionVisibleId);
        setActionVisibleId('');
      },
      onCancel: () => {
        setActionVisibleId('');
      },
    });
  };

  const onReport = () => {
    setIsOpenRequestModal(true);
    setIsOptionsPopoverOpen(false);
  };

  const onMarkAsBooked = () => {
    handleConfirmBooked();
    setIsOptionsPopoverOpen(false);
  };

  const onRestartRecognition = () => {
    handleConfirmRecognizeFile();
    setIsOptionsPopoverOpen(false);
  };

  const columns: TableColumnType<DocumentFile>[] = [
    {
      key: 'adminStatus',
      dataIndex: 'adminStatus',
      render: (status) => <DocumentsStatusTag status={status} />,
      width: '10%',
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
      render: (name, record) => (
        <S.DocumentNameWrapper align="center">
          {record.documentMetadata?.amount && <S.Point />}
          <S.DocumentName>{name || 'No name'}</S.DocumentName>
        </S.DocumentNameWrapper>
      ),
      width: '45%',
    },
    {
      key: 'type',
      dataIndex: 'type',
      title: 'Type',
      render: (type) => <S.DocumentType>{type}</S.DocumentType>,
      width: '20%',
    },
    {
      key: 'referenceId',
      dataIndex: 'referenceId',
      title: 'Bob50 ID',
      render: (referenceId, record) => (
        <ReferenceInput
          id={record.id}
          defaultValue={referenceId}
          onUpdateReference={(id, referenceId) =>
            handleUpdateFile(id, 'referenceId', referenceId)
          }
        />
      ),
      width: '10%',
    },
    {
      key: 'issueDate',
      dataIndex: 'documentMetadata',
      title: 'Issue date',
      render: ({ issueDate }) => (
        <span>{issueDate && dayjs(issueDate).format('DD.MM.YYYY')}</span>
      ),
      width: '10%',
    },
    {
      key: 'actions',
      render: (_, record) => {
        return (
          <Flex gap={20} align="center">
            <a
              href={record.url}
              download={record.name}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                handleUpdateFile(record.id, 'adminStatus', 'processing', false);
                setFiles((prev) =>
                  prev.map((file) =>
                    file.id === record.id
                      ? { ...file, adminStatus: 'processing' }
                      : file
                  )
                );
              }}
            >
              <S.DownloadIcon>
                <IconDownload width={18} height={18} />
              </S.DownloadIcon>
            </a>
            <Popover
              visible={actionVisibleId === record.id && isOptionsPopoverOpen}
              onVisibleChange={(value) => {
                setActionVisibleId(record.id);
                setIsOptionsPopoverOpen(value);
                setIsOpenRequestModal(false);
              }}
              trigger="click"
              placement="bottomRight"
              arrow={false}
              overlayClassName="ui-popover-legal-actions"
              title={
                <S.Actions ref={ref}>
                  <S.PopoverItem onClick={onReport}>
                    Report an error
                  </S.PopoverItem>
                  <S.PopoverItem onClick={onMarkAsBooked}>
                    Mark as Booked
                  </S.PopoverItem>
                  <S.PopoverItem onClick={onRestartRecognition}>
                    Restart recognition
                  </S.PopoverItem>
                </S.Actions>
              }
            >
              <S.MoreIcon>
                <IconMoreVertical width={18} height={18} />
              </S.MoreIcon>
            </Popover>
          </Flex>
        );
      },
      width: '5%',
    },
  ];

  useEffect(() => {
    resetScroll();
    setIsLastPage(false);
  }, [filterOptions]);

  const onSelectChange = (newSelectedRowKeys: Key[]) =>
    setSelectedRowKeys(newSelectedRowKeys);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    hideSelectAll: true,
  };

  const handleBookFiles = () => {
    updateFiles(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          ids: selectedRowKeys as string[],
          adminStatus: 'processed',
        },
      },
      {
        onSuccess: () => {
          message.success('Files were updated');
          setSelectedRowKeys([]);
          refetch();
        },
      }
    );
  };

  const handleFileRecognize = (id: string) => {
    recognizeFile(
      {
        parameter: {
          companyId: companyId!,
          id,
        },
      },
      {
        onSuccess: () => {
          message.success('File was recognized');
          refetch();
        },
      }
    );
  };

  const defaultDownloadModalValues = {
    ...(params?.adminStatus && {
      adminStatus: params?.adminStatus,
    }),
    ...(params?.type && {
      type: params?.type,
    }),
    ...(params?.dateFrom &&
      params?.dateTo && {
        period: [dayjs(params?.dateFrom), dayjs(params?.dateTo)],
      }),
  };

  return (
    <Flex gap={24} vertical>
      {contextHolder}

      {title}

      <Flex justify="space-between" align="center">
        <FilterConstructor<DocumentFile>
          columns={columns}
          controls={{
            left: [
              {
                type: 'search',
                formName: 'term',
                params: {
                  clearIcon: <IconClose width={16} height={16} />,
                  allowClear: true,
                },
              },
              {
                type: 'divider',
              },
              {
                type: 'range-picker',
                formName: 'date',
              },
              {
                type: 'select',
                formName: 'type',
                params: {
                  options: typesList,
                  placeholder: 'Type',
                  width: 165,
                  allowClear: true,
                },
              },
              {
                type: 'select',
                formName: 'adminStatus',
                params: {
                  options: statusesList,
                  placeholder: 'Status',
                  width: 133,
                  allowClear: true,
                },
              },
            ],
            right: [
              {
                type: 'custom-component',
                component: (
                  <S.DownloadButton
                    size="large"
                    type="text"
                    onClick={() => setIsDownloadModalOpen(true)}
                  >
                    <IconDownload />
                  </S.DownloadButton>
                ),
              },
              {
                type: 'custom-component',
                component: (
                  <S.BookedButton
                    size="large"
                    type="default"
                    disabled={!selectedRowKeys?.length}
                    onClick={handleConfirmBookedFiles}
                  >
                    Mark as Booked
                  </S.BookedButton>
                ),
              },
            ],
          }}
          onChange={setFilterOptions}
          onRequest={refetch}
          withSearchParams={withSearchParams}
        />
      </Flex>

      <S.TableContainer>
        <Table
          rowKey={({ id }) => id}
          rowSelection={rowSelection}
          rowClassName={() => 'ui-table-custom-row'}
          onRow={({ id }) => ({
            onClick: () => navigate(`/documents/bob50/${id}`),
          })}
          dataSource={files}
          columns={columns}
          loading={loading}
          pagination={false}
          scroll={{ x: 720 }}
        />
      </S.TableContainer>

      <SendRequestModal
        open={!!actionVisibleId && isOpenRequestModal}
        onRequest={handleReport as any}
        onCancel={() => {
          setIsOpenRequestModal(false);
          setActionVisibleId('');
        }}
      />

      <DownloadDocumentsModal
        page={currentPage}
        open={isDownloadModalOpen}
        onCancel={() => setIsDownloadModalOpen(false)}
        onRefreshList={refetch}
        defaultValues={defaultDownloadModalValues}
      />
    </Flex>
  );
};

const ReferenceInput: FC<
  InputProps & {
    onUpdateReference: (id: string, referenceId?: string) => void;
    id: string;
  }
> = ({ onUpdateReference, id, defaultValue, ...rest }) => {
  const [value, setValue] = useState<string | undefined>(
    defaultValue as string
  );

  return (
    <S.ReferenceInput
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => onUpdateReference(id, value)}
      defaultValue={defaultValue}
      {...rest}
    />
  );
};

export default DocumentsContent;
